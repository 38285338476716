<template>
  <div class="cashBack-wapper">
    <div class="cash-title-wapper hidden-sm-and-up">
      <span>{{ $t("现金返还") }}</span>
    </div>
    <div class="cash-list">
      <div class="cash hidden-xs-only">
        <span v-if="$helper.getLang() != 'BR'">{{ $t("现金") }}</span>
        <span>{{ $t("返还") }}</span>
      </div>
      <swiper :options="swiperOptionThumbs" class="swiper" ref="swiper">
        <swiper-slide v-for="item,index in list" :key="index" >
          <div class="cash-item"  :style="`background-image: url(${require('@/assets/images/cb'+(index%5+1)+'.png')});`" @click="toCashBack(item)">
            <span class="item-title" :title="item.name">{{ $t(item.name) }}</span>
            <span class="item-num">{{ item.num }}%</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      swiperOptionThumbs: {
        loop: false,
        slidesPerView: 5,
        centeredSlides: false,
        breakpoints: {
          540: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          768:{
            slidesPerView: 3,
            spaceBetween: 0,
          },
          900: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          
        },
      },
    }
  },
  props:{
    cashbackList:{
      default:[]
    }
  },
  computed:{
    list(){
      return this.cashbackList.map(item=>{
        let obj = item.vipwater.find(vipwaterItem=>vipwaterItem.gbc_viplvl===this.$store.state.userInfo.level_id)
        return {
          ...item,
          num:obj?obj.gbc_rate:0
        }
      })
      
    }
  },
  methods:{
    /**
     * 跳转页面
     * */ 
    toCashBack(item){
      this.$router.push(`/all-game?tab=${item.id}`)
    }
  }
}
</script>

<style scoped>
.cashBack-wapper{
  background-color: transparent;
  height: 112px;
  margin-bottom: 20px;
}
.cash{
  width: 139px;
  height: 112px;
  background-image: url(../../../assets/images/cashback-bg2.png);
  background-size: 100% 100%;
  flex-shrink: 0;
  padding-left:23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #91AAD1;
  font-size: 16px;
  font-weight: bold;
}
.cash span:nth-child(2){
  text-indent: 1em;
}
.cash-item{
  width: 100%px;
  padding-bottom: 46%;
  background-image: url(../../../assets/images/cb1.png);
  background-size: 100% 100%;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}
.item-title{
  position: absolute;
  top: 10%;
  left: 20%;
  max-width: 65%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-num{
  position: absolute;
  top: 53%;
  left: 44%;
  color: #1BB23C;
  font-weight: bold;
  font-size: 15px;
}
.cash-list{
  display: flex;
  align-items: center;
  overflow: auto;
  border-radius: 20px;
  background: #111923;
}
.swiper{
  flex: 1;
}
.cash-title-wapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #91AAD1;
  font-weight: bold;
  margin-bottom: 27px;
}

@media screen and (max-width:768px) {
  .cashBack-wapper{
    height: auto;
    padding: 20px 12px 12px;
    background-color: #111923;
    border-radius: 12px;
  }
}
@media screen and (max-width:540px){
  .cash-item{
    font-size: 12px;
  }
  .item-num{
    font-size: 12px;
  }
}
</style>